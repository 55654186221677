

.imageWrapper > span{
 
  border-radius: 50%;
   border:3px solid gray
 
}
.imageWrapper >  img{
 
object-fit: fit !important;
 
  -webkit-filter: grayscale(100%) !important; /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) !important;
}